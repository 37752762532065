import logo2 from './../assets/logo.png'
import React, { useState, useContext } from "react";
import { MdDashboard, MdList, MdShoppingBasket, MdSettings, MdOutlineHelp, MdAreaChart, MdAppRegistration, MdSyncAlt, MdOutlineAnalytics } from 'react-icons/md'
import { BsCaretDownFill, BsCaretUpFill, BsDot } from 'react-icons/bs'
import { SiSalesforce } from 'react-icons/si'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'
import { GlobalContext } from "../contexts/global";
import { useNavigate } from 'react-router-dom'

function Sidebar() {

    const Navigate = useNavigate();
    const { conexao, loading, limpaCNPJ, setLoading } = useContext(GlobalContext)

    function AbreMenu(item) {
        if (item == 'clientes') {
            setMenuClientes(!menuClientes)
            setMenuFinanceiro(false)
            setMenuComercial(false)
            setMenuAnalytics(false)
        }
        if (item == 'financeiro') {
            setMenuFinanceiro(!menuFinanceiro)
            setMenuClientes(false)
            setMenuComercial(false)
            setMenuAnalytics(false)
        }
        if (item == 'comercial') {
            setMenuComercial(!menuComercial)
            setMenuFinanceiro(false)
            setMenuClientes(false)
            setMenuAnalytics(false)
        }
        if (item == 'analytics') {
            setMenuAnalytics(!menuAnalytics)
            setMenuFinanceiro(false)
            setMenuClientes(false)
            setMenuComercial(false)
        }
    }

    const [menuClientes, setMenuClientes] = useState(false)
    const [menuFinanceiro, setMenuFinanceiro] = useState(false)
    const [menuComercial, setMenuComercial] = useState(false)
    const [menuAnalytics, setMenuAnalytics] = useState(false)

    const [modalPush, setModalPush] = useState(false)
    const [msgPush, setMsgPush] = useState({})

    function EnviarPush() {

        setLoading(true)

        const url = conexao.endereco + '/push/enviar'

        let requisicao = new XMLHttpRequest()

        const body = {
            email: msgPush.email,
            titulo: msgPush.titulo,
            mensagem: msgPush.mensagem
        }

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                setMsgPush({})
                alert('Mensagem Enviada com Sucesso!')
            } else {
                alert('Falha ao Enviar a Mensagem!')
                setModalPush(true)
            }
        }
    }

    return (

        <div className='app'>
            <div className='sidebar'>
                <div className='sidebar-topo'>
                    <h1 style={{ color: 'white', fontSize: '18pt' }}>Administração</h1>
                </div>
                <div className='sidebar-content'>
                    <ul>
                        <li
                            onClick={() => { Navigate('/dashboard') }}>
                            <div className='menu-icone'>
                                <MdDashboard size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Dashboard
                            </div>
                        </li>
                        <li
                            onClick={() => { AbreMenu('clientes') }}>
                            <div className='menu-icone'>
                                <MdList size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Clientes
                                {!menuClientes ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuClientes &&
                            <ul className='submenu'>
                                {/* <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Clientes
                                    </div>
                                </li> */}
                                <li onClick={() => { Navigate('/clientes/licenciamento') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Licenciamento
                                    </div>
                                </li>
                                <li onClick={() => { setModalPush(true) }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Enviar Push
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('financeiro') }}>
                            <div className='menu-icone'>
                                <MdAreaChart size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Financeiro
                                {!menuFinanceiro ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuFinanceiro &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Contas a Receber
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Contas a Pagar
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Fluxo de Caixa
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('comercial') }}>
                            <div className='menu-icone'>
                                <SiSalesforce size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Comercial
                                {!menuComercial ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuComercial &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/comercial/propostas') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Propostas
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('analytics') }}>
                            <div className='menu-icone'>
                                <MdOutlineAnalytics size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Analytics
                                {!menuAnalytics ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuAnalytics &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Coletas
                                    </div>
                                </li>
                            </ul>
                        }

                    </ul>
                </div>
            </div>

            <Modal
                show={modalPush}
                onHide={() => { setModalPush(false) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Envio de Notificação Push</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Título</Form.Label>
                                <Form.Control size="sm" type="text" onChange={(e) => { setMsgPush({ ...msgPush, titulo: e.target.value }) }} value={msgPush.titulo} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Mensagem</Form.Label>
                                <Form.Control size="sm" as="textarea" rows={3} onChange={(e) => { setMsgPush({ ...msgPush, mensagem: e.target.value }) }} value={msgPush.mensagem} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>E-mail Usuário</Form.Label>
                                <Form.Control size="sm" type="email" onChange={(e) => { setMsgPush({ ...msgPush, email: e.target.value }) }} value={msgPush.email} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalPush(false) }}>
                        Fechar
                    </Button>
                    <Button variant="dark" onClick={() => {

                        EnviarPush()
                        setModalPush(false)

                    }}>Enviar</Button>
                </Modal.Footer>
            </Modal>

        </div>

    )
}

export default Sidebar